import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $: any;

@Pipe({ name: 'string' })
export class StringPipe implements PipeTransform {

  public url: string;

  constructor(private domSanitizer: DomSanitizer) {
    const lu = JSON.parse(sessionStorage.getItem('loggedUser')).data;
    this.url = lu.link;
  }

  transform(string: string, arg?: string, arg2?: any): SafeResourceUrl | string {
    if (arg === 'strip_tags') {
      return this.strip_tags(string);
    }
    if (arg === 'decodeURI') {
      return this.decode(string);
    }
    if (arg === 'truncate') {
      return this.truncate(string, arg2);
    }
    return string;
  }

  strip_tags(string: string): string {
    return string.replace(/(<([^>]+)>)/ig, '');
  }

  decode(string: string): string {
    const txt = document.createElement('textarea');
    txt.innerHTML = string;

    string = txt.value;

    return string;
  }

  truncate(string: string, length: number) {
    return string.substring(0, length);
  }

}
