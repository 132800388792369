import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Course } from '../model/course.model';
import { CourseService } from '../service/course.service';
// import { CourseMemoryService as CourseService } from '../service/course.memory.service';

@Injectable({providedIn: 'root'})
export class CourseRepository {

  constructor(public courseService: CourseService) {}

  collection(): Promise<Array<Course>> {
    let courseArray: Array<Course>;
    courseArray = [];
    return new Promise((resolve, reject) => {
      this.courseService.fetchAll().subscribe((response: any) => {
        response.map((item: any) => {
          courseArray.push(this.hydrate(item));
        });
        resolve(courseArray);
      }, err => {
        reject(err);
      });
    });
  }

  entity(id: number): Promise<Course> {
    return new Promise((resolve, reject) => {
      this.courseService.find(id).subscribe((response: any) => {
        console.log(response);
        resolve(this.hydrate(response.data));
      }, err => {
        reject(err);
      });
    });
  }

  save(course: Course): Promise<Course> {
    return new Promise((resolve, reject) => {
      let request: Observable<any>;

      console.log(course);


      if (course.id === undefined) {
        request = this.courseService.create(course);
      } else {
        request = this.courseService.update(course.id, course);
      }

      request.subscribe(res => {
        resolve(this.hydrate(res.data));
      }, err => {
        reject(err);
      });

    });
  }

  remove(course: Course) {
    return new Promise((resolve, reject) => {
      this.courseService.delete(course.id).subscribe(() => {
        resolve();
      }, err => {
        reject(err);
      });
    });
  }

  hydrate(data: any) {
    const course = new Course();

    course.id = data.id;
    course.title = data.title;
    course.description = data.description;
    course.image = data.image || '/assets/img/no-image.jpg';

    return course;

  }

}
