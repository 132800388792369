import { Routes } from '@angular/router';
import { RedirectComponent } from './redirect.component';

export const AppRoutes: Routes = [
  {
    path: 'redirect',
    component: RedirectComponent
  },
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full'
  },
  {
    path: 'intro',
    loadChildren: './intro/intro.module#IntroModule'
  },
  {
    path: 'courses',
    loadChildren: './courses/courses.module#CoursesModule'
  },
  {
    path: 'editor/:id',
    loadChildren: './editor/editor.module#EditorModule'
  },
  {
    path: 'gallery',
    loadChildren: './gallery/gallery.module#GalleryModule'
  }
];

