import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Course } from 'src/app/@core/model/course.model';
import { CourseRepository } from 'src/app/@core/repository/course.repository';

@Component({
  selector: 'app-courses-dialog',
  templateUrl: 'course-dialog.component.html',
})
export class CoursesDialogComponent {
  course: Course;

  isPosting: boolean;
  fileSelected: any;

  courseForm: FormGroup;

  constructor(
    public courseRepository: CourseRepository,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CoursesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGenerate();

    if (data.course) {
      this.course = data.course;
      this.courseForm.patchValue({
        title: this.course.title,
        description: this.course.description,
        image: this.course.image
      });
      this.fileSelected = this.course.image;
    } else {
      this.course = new Course();
    }
  }

  formGenerate() {
    this.courseForm = this.formBuilder.group({
      id: '',
      title: ['', Validators.required],
      description: ['', Validators.required],
      image: ['']
    });
  }

  onSubmit() {
    const form = this.courseForm;
    console.log(form.value);

    if (!form.valid) {
      console.log('form.invalid', form);
      return;
    }

    this.isPosting = true;

    const formValue = form.value;

    const course = new Course();
    course.id = this.course.id;
    course.title = formValue.title;
    course.description = formValue.description;
    course.image = this.fileSelected;

    this.courseRepository.save(course).then(response => {
      this.closeDialog(response);
    });

  }

  closeDialog(course: Course): void {
    this.isPosting = false;
    this.courseForm.reset();
    this.dialogRef.close(course);
  }

  getPhoto(image: {path: string}) {
    this.fileSelected = image.path;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  random(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

}
