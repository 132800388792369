import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Video } from '../model/video.model';
import { VideoService } from '../service/video.service';
// import { VideoMemoryService as VideoService } from '../service/video.memory.service';

@Injectable({providedIn: 'root'})
export class VideoRepository {

  constructor(public videoService: VideoService) {}

  collection(folderId: number): Promise<Array<Video>> {
    let videoArray: Array<Video>;
    videoArray = [];
    return new Promise((resolve, reject) => {
      this.videoService.fetchAll(folderId).subscribe((response: any) => {
        response.map((item: any) => {
          videoArray.push(this.hydrate(item));
        });
        resolve(videoArray);
      }, err => {
        reject(err);
      });
    });
  }

  save(video: Video): Promise<Video> {
    return new Promise((resolve, reject) => {
      let request: Observable<any>;

      if (!video.id) {
        request = this.videoService.create(video);
      } else {
        request = this.videoService.update(video.id, video);
      }

      request.subscribe(res => {
        resolve(this.hydrate(res.data));
      }, err => {
        reject(err);
      });

    });
  }

  remove(video: Video) {
    return new Promise((resolve, reject) => {
      this.videoService.delete(video.id).subscribe(() => {
        resolve();
      }, err => {
        reject(err);
      });
    });
  }

  hydrate(data: any) {
    const video = new Video();

    video.id = data.id;
    video.name = data.name || 'Untitled';
    video.path = data.path;
    video.video_folder_id = data.video_folder_id;

    return video;
  }

}
