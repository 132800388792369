import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Video } from 'src/app/@core/model/video.model';
import { VideoRepository } from 'src/app/@core/repository/video.repository';
import { StreamService } from 'src/app/@core/service/stream.service';

@Component({
  selector: 'app-video-dialog',
  templateUrl: 'video-dialog.component.html',
})
export class VideoDialogComponent {

  videoRepositoryGateway: VideoRepository;

  video: Video;

  isPosting: boolean;
  fileSelected: any;

  videoForm: FormGroup;

  thumbProcessing: boolean;

  constructor(
    public videoRepository: VideoRepository,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<VideoDialogComponent>,
    public streamService: StreamService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGenerate();

    if (data.video) {
      this.video = data.video;
      this.videoForm.patchValue({
        name: this.video.name,
        path: this.video.path,
        folder_id: this.video.video_folder_id
      });
    } else {
      this.video = new Video();
      this.video.video_folder_id = data.folder_id;
    }
  }

  formGenerate() {
    this.videoForm = this.formBuilder.group({
      id: '',
      name: ['', Validators.required],
      path: ['', Validators.required],
      folder_id: ['']
    });
  }

  onSubmit() {
    const form = this.videoForm;
    console.log(form.value);

    if (!form.valid) {
      console.log('form.invalid', form);
      return;
    }

    this.isPosting = true;

    const formValue = form.value;

    const video = new Video();
    video.id = this.video.id;
    video.name = formValue.name;
    video.path = formValue.path;
    video.video_folder_id = this.video.video_folder_id;

    this.getVideoRepositoryGateway().save(video).then(response => {
      this.closeDialog(response);
    });

  }

  getFile(file: {path: string}) {
    this.thumbProcessing = true;
    this.checkStatus(file.path);
  }

  get file(): string {
    return this.videoForm.get('path').value;
  }

  checkStatus(id: string) {
    setTimeout(() => {
      this.streamService.ping(id).subscribe(res => {
        this.showVideo(id);
      }, err => {
        if (err.status === 200) {
          this.showVideo(id);
        } else {
          this.checkStatus(id);
        }
      });
    }, 3000);
  }

  showVideo(id: string) {
    this.thumbProcessing = false;
    this.videoForm.get('path').setValue(id);
  }

  closeDialog(video: Video): void {
    this.isPosting = false;
    this.videoForm.reset();
    this.dialogRef.close(video);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getVideoRepositoryGateway(): VideoRepository {
    if (!this.videoRepositoryGateway) {
      this.videoRepositoryGateway = this.videoRepository;
    }
    return this.videoRepositoryGateway;
  }

}
