import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Folder } from '../model/folder.model';
import { FolderService } from '../service/folder.service';
// import { FolderMemoryService as FolderService } from '../service/folder.memory.service';

@Injectable({providedIn: 'root'})
export class FolderRepository {

  constructor(public folderService: FolderService) {}

  collection(): Promise<Array<Folder>> {
    let folderArray: Array<Folder>;
    folderArray = [];
    return new Promise((resolve, reject) => {
      this.folderService.fetchAll().subscribe((response: any) => {
        response.data.map((item: any) => {
          folderArray.push(this.hydrate(item));
        });
        resolve(folderArray);
      }, err => {
        reject(err);
      });
    });
  }

  entity(id: number): Promise<Folder> {
    let folderArray: Array<Folder>;
    folderArray = [];
    return new Promise((resolve, reject) => {
      this.folderService.find(id).subscribe((response: any) => {
        resolve(this.hydrate(response.data));
      }, err => {
        reject(err);
      });
    });
  }

  save(folder: Folder): Promise<Folder> {
    return new Promise((resolve, reject) => {
      let request: Observable<any>;

      if (!folder.id) {
        request = this.folderService.create(folder);
      } else {
        request = this.folderService.update(folder.id, folder);
      }

      request.subscribe(res => {
        resolve(this.hydrate(res.data));
      }, err => {
        reject(err);
      });

    });
  }

  remove(folder: Folder) {
    return new Promise((resolve, reject) => {
      this.folderService.delete(folder.id).subscribe(() => {
        resolve();
      }, err => {
        reject(err);
      });
    });
  }

  hydrate(data: any) {
    const folder = new Folder();

    folder.id = data.id;
    folder.name = data.name || 'Pasta sem título';

    return folder;
  }

}
