import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SafePipe } from './safe.pipe';
import { StreamPipe } from './stream.pipe';
import { StringPipe } from './string.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    SafePipe,
    StreamPipe,
    StringPipe
  ],
  exports: [
    SafePipe,
    StreamPipe,
    StringPipe
  ]
})

export class PipeModule { }
