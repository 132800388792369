import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Folder } from 'src/app/@core/model/folder.model';
import { FolderRepository } from 'src/app/@core/repository/folder.repository';

@Component({
  selector: 'app-folder-dialog',
  templateUrl: 'folder-dialog.component.html',
})
export class FolderDialogComponent {
  folder: Folder;

  isPosting: boolean;
  fileSelected: any;

  folderForm: FormGroup;

  constructor(
    public folderRepository: FolderRepository,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<FolderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGenerate();

    if (data.folder) {
      this.folder = data.folder;
      this.folderForm.patchValue({
        name: this.folder.name,
      });
    } else {
      this.folder = new Folder();
    }
  }

  formGenerate() {
    this.folderForm = this.formBuilder.group({
      id: '',
      name: ['', Validators.required]
    });
  }

  onSubmit() {
    const form = this.folderForm;
    console.log(form.value);

    if (!form.valid) {
      console.log('form.invalid', form);
      return;
    }

    this.isPosting = true;

    const formValue = form.value;

    const folder = new Folder();
    folder.id = this.folder.id;
    folder.name = formValue.name;

    this.folderRepository.save(folder).then(response => {
      this.closeDialog(response);
    });

  }

  closeDialog(folder: Folder): void {
    this.isPosting = false;
    this.folderForm.reset();
    this.dialogRef.close(folder);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
