import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import {
    MatButtonModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    MatCheckboxModule
} from '@angular/material';

import { UploadPluginComponent } from './upload.component'
import { ModalUploadPluginComponent } from './modal-upload/modal-upload.component';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';

import { environment } from 'src/environments/environment';
import { AngularFireStorageModule } from '@angular/fire/storage';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSnackBarModule,
    ImageCropperModule,
    AngularFireModule.initializeApp(environment.firebase, 'FireMain'),
    AngularFireStorageModule,
  ],
  declarations: [
    UploadPluginComponent,
    ModalUploadPluginComponent,

  ],
  exports: [
    UploadPluginComponent,
    ModalUploadPluginComponent,

  ],
  entryComponents: [
    ModalUploadPluginComponent,

  ]
})

export class UploadModule { }
