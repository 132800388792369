import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from './@core/service/auth.service';

@Component({
  selector: 'app-redirect',
  template: `<p style="margin: 40px; text-align: center;">Redirecionando...</p>`
})

export class RedirectComponent {
  token: string;
  trail: string;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public authService: AuthService
  ) {
    this.activatedRoute.queryParams
      .subscribe((params: Params) => {
        this.token = params.token;
        this.authenticate();
      });
  }

  authenticate() {
    sessionStorage.setItem('apiURL', 'https://apiv3.plataformaasap.com.br');
    localStorage.setItem('apiURL', 'https://apiv3.plataformaasap.com.br');

    const data = {
      token: this.token
    };

    this.authService.setSessionToken(data)
      .then((response) => {
        this.router.navigate(['/']);
      });
  }

}
