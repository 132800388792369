import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Lesson, Quiz, QuizQuestion, QuizQuestionOptions } from '../model/lesson.model';
import { LessonService } from '../service/lesson.service';

@Injectable({providedIn: 'root'})
export class LessonRepository {

  constructor(public lessonService: LessonService) {}

  collection(courseId: number): Promise<Array<Lesson>> {
    let lessonArray: Array<Lesson>;
    lessonArray = [];
    return new Promise((resolve, reject) => {
      this.lessonService.fetchAll(courseId).subscribe((response: any) => {
        response.sort(this.compare).map((item: any) => {
          lessonArray.push(this.hydrate(item));
        });
        resolve(lessonArray);
      }, err => {
        reject(err);
      });
    });
  }

  save(lesson: Lesson): Promise<Lesson> {
    return new Promise((resolve, reject) => {
      let request: Observable<any>;

      if (!lesson.id) {
        request = this.lessonService.create(lesson);
      } else {
        request = this.lessonService.update(lesson.id, lesson);
      }

      request.subscribe(res => {
        resolve(this.hydrate(res.data));
      }, err => {
        reject(err);
      });

    });
  }

  remove(lesson: Lesson) {
    return new Promise((resolve, reject) => {
      this.lessonService.delete(lesson.id).subscribe(() => {
        resolve();
      }, err => {
        reject(err);
      });
    });
  }

  order(ids: Array<number>): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.lessonService.order(ids).subscribe(() => {
        resolve(true);
      }, err => {
        reject(false);
      });
    });
  }

  hydrate(data: any) {
    const lesson = new Lesson();

    lesson.id = data.id;
    lesson.type = data.type;
    lesson.course_id = data.course_id;
    lesson.title = data.title || 'Lição sem título';
    lesson.content = data.content || '';
    lesson.quiz = this.quizHydrate(data.quiz);

    return lesson;
  }

  quizHydrate(data: any): Quiz {
    const quiz = new Quiz();

    if(data) {
      quiz.title = data.title || 'Quiz sem título';
      quiz.subtitle = data.subtitle || 'A descrição do seu quiz aqui';
      quiz.questions = this.questionsHidrate(data.questions);
    }

    return quiz;
  }

  questionsHidrate(data: any): Array<QuizQuestion> {

    let quizQuestionArray: Array<QuizQuestion>;

    if(data && Array.isArray(data)) {
      quizQuestionArray = data.map(item => {
        const question = new QuizQuestion();

        question.title = item.title || 'Pergunta sem título';
        question.correct = item.correct;
        question.options = item.options || [new QuizQuestionOptions()];

        return question;
      });
    } else {
      quizQuestionArray = [];
      quizQuestionArray.push(new QuizQuestion());
    }

    return quizQuestionArray;
  }

  compare(a: any, b: any) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

}
