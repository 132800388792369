import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { RedirectComponent } from './redirect.component';
import { PipeModule } from './@core/pipe/pipe.module';
import { SimpleNotificationsModule, NotificationsService } from 'angular2-notifications';
import { SortablejsModule } from 'ngx-sortablejs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forRoot(AppRoutes),
    SharedModule,
    PipeModule,
    SimpleNotificationsModule.forRoot(),
    SortablejsModule
  ],

  providers: [
    NotificationsService,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
