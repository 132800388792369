import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AsapBaseService } from 'asap-crud';

@Injectable({providedIn: 'root'})
export class AuthService extends AsapBaseService {

    constructor(public http: HttpClient) {
        super(http);
        this.basePath = 'api';
        this.entityName = 'v2';
    }

    public getUserLogged() {
        return this.getResources({router: 'client/user/authenticated'});
    }

    public setSessionToken(data: {token: string}): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const token = 'Bearer' + ' ' + data.token;
            sessionStorage.setItem('token', token);

            this.getUserLogged().subscribe((response: any) => {
              sessionStorage.setItem('loggedUser', JSON.stringify(response));
              localStorage.setItem('client', response.data.client_id);

              sessionStorage.setItem('streamURL', response.data.link);

              resolve(true);

            }, err => reject(err));
        });
    }

    public getData() {

    }

}
