import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FirebaseApp } from '@angular/fire';
import { storage } from 'firebase';
import Swal from 'sweetalert2';

import { StreamService } from 'src/app/@core/service/stream.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-modal-upload-plugin',
  templateUrl: './modal-upload.component.html',
  styleUrls: ['./modal-upload.component.css'],
})

export class ModalUploadPluginComponent {

  typeImage = false;
  uploadMode = true;
  fileName: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperReady = false;
  percentage = 0;
  storeRef: storage.Reference;
  archive: any;
  resizeToWidth: number;
  aspectRatio: string;
  horizontal: boolean;
  format: string;
  onlyType: string;
  maintainAspectRatio = true;

  constructor(
    public dialogRef: MatDialogRef<ModalUploadPluginComponent>,
    public streamService: StreamService,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(FirebaseApp) fb: any
  ) {
    this.storeRef = fb.storage().ref();
    this.typeImage = (data.type === 'image') ? true : false;
    this.resizeToWidth = data.resizeToWidth;
    this.aspectRatio = data.aspectRatio;
    this.horizontal = data.horizontal;
    this.onlyType = data.onlyType;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  loadImageFailed() {
    console.log('loadImageFailed');
  }

  reset() {
    this.croppedImage = '';
    this.fileName = '';
    this.imageChangedEvent = '';
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCroppedBase64(image: string) {
    this.croppedImage = image;
  }

  imageLoaded() {
    this.cropperReady = true;
  }

  imageLoadFailed() {
    console.log('Load failed');
  }

  UploadImage(event: any) {
    if (event.srcElement.files[0]) {
      this.imageChangedEvent = event;
      this.fileName = event.srcElement.files[0].name;
      this.format = event.srcElement.files[0].type;
    }
  }

  dataURItoBlob(dataURI: any) {
    const type = 'image/png';
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const bb = new Blob([ab], { type });
    return bb;
  }

  sendImage() {
    const imagee = this.dataURItoBlob(this.croppedImage);
    const caminho = this.storeRef.child('images/' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10) + this.fileName);
    const tarefa = caminho.put(imagee);
    tarefa.on('state_changed', (snapshot) => {
      this.percentage = Math.round((tarefa.snapshot.bytesTransferred / tarefa.snapshot.totalBytes) * 100);
    }, error => {
    }, () => {
      if (tarefa.snapshot.state === 'success') {
        caminho.getDownloadURL().then(url => {
          this.dialogRef.close({
            path: url,
            name: this.fileName,
            size: tarefa.snapshot.totalBytes,
            format: this.format
          });
        });
      }
    });
  }

  UploadArchive(event) {
    if (event && this.whiteListUploads(event.target.files[0].type)) {
      const file = event.srcElement.files[0];
      this.archive = file;
      this.fileName = file.name;
      this.format = file.type;
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao carregar o arquivo.',
        text: 'Verifique se o arquivo tem o formato permitido.'
      });
    }
  }

  sendArchive() {
    console.log('sendArchive', this.format);
    if (this.format.split('/')[0] === 'video') {
      this.sendToStream();
    } else {
      this.sendToFirebase();
    }
  }

  sendToStream() {
    console.log(this.archive);

    this.streamService.upload(this.archive)
      .subscribe((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.percentage =  Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.dialogRef.close({
            path: event.body.uuidVideo,
            name: this.fileName,
            format: this.format
          });
        }
      });
  }

  sendToFirebase() {
    const name = 'archives/' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10) + '_' + this.fileName;
    const caminho = this.storeRef.child(name);
    const tarefa = caminho.put(this.archive);
    tarefa.on('state_changed', (snapshot) => {
      this.percentage = Math.round((tarefa.snapshot.bytesTransferred / tarefa.snapshot.totalBytes) * 100);
    }, error => {
    }, () => {
      if (tarefa.snapshot.state === 'success') {
        caminho.getDownloadURL().then(url => {
          this.dialogRef.close({
            path: url,
            name: this.fileName,
            format: this.format
          });
        });
      }
    });
  }

  private whiteListUploads(fileType: any): boolean {
    switch (fileType) {
      case 'image/png':
        return true;
      case 'image/jpeg':
        return true;
      case 'image/jpg':
        return true;
      case 'image/gif':
        return true;
      case 'image/png':
        return true;
      case 'application/msword':
        return true;
      case 'application/pdf':
        return true;
      case 'application/zip':
        return true;
      case 'application/vnd.ms-excel':
        return true;
      case 'video/x-flv':
        return true;
      case 'video/mp4':
        return true;
      case 'application/x-mpegURL':
        return true;
      case 'video/MP2T':
        return true;
      case 'video/3gpp':
        return true;
      case 'video/quicktime':
        return true;
      case 'video/x-msvideo':
        return true;
      case 'video/x-ms-wmv':
        return true;
      case '':
        return true;
      default:
        return true;
    }
  }
}
