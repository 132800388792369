import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SortablejsModule } from 'ngx-sortablejs';

import { MaterialDesignModule } from './material.module';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UploadModule } from './upload/upload.module';
import { CoursesDialogComponent } from './course-dialog/course-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from '../@core/pipe/pipe.module';
import { FolderDialogComponent } from './folder-dialog/folder-dialog.component';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UploadModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialDesignModule,
    PipeModule,
    SortablejsModule,
    NgScrollbarModule
  ],
  declarations: [
    NavbarComponent,
    SidebarComponent,
    CoursesDialogComponent,
    FolderDialogComponent,
    VideoDialogComponent
  ],
  exports: [
    MaterialDesignModule,
    NavbarComponent,
    SidebarComponent,
    UploadModule,
    CoursesDialogComponent,
    FolderDialogComponent,
    VideoDialogComponent,
    NgScrollbarModule
  ],
  entryComponents: [
    CoursesDialogComponent,
    FolderDialogComponent,
    VideoDialogComponent
  ]
})
export class SharedModule { }
