import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class StreamService {

  private host: string;
  protected options: any;

  constructor(
      public http: HttpClient
  ) {
    this.host = sessionStorage.getItem('streamURL');
    this.options = {
      headers: {
        Authorization: sessionStorage.getItem('token'),
        Accept: 'application/json'
      }
    };
  }

  ping(id: string): Observable<any> {
    return this.http.head(this.host + 'videos/' + id + '/480', {observe : 'response'});
  }

  upload(data: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', data);

    const headerOptions = {
      Authorization: sessionStorage.getItem('token'),
      Accept: 'application/json'
    };

    const req = new HttpRequest('POST', this.host + 'videos/upload', formData, {
      reportProgress: true,
      responseType: 'json',
      headers: new HttpHeaders(headerOptions)
    });

    return this.http.request(req);
  }

}
