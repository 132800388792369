import { Injectable } from '@angular/core';
import { first } from "rxjs/operators";
import { Observable } from 'rxjs/internal/Observable';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class LessonService {
  public endpoint: string;

  constructor(
    public httpClient: HttpClient
  ) {
    this.endpoint = '/api/v2/client/lesson';
  }

  fetchAll(courseId: number): Observable<any> {
    return this.httpClient.get(`${this.host}/api/v2/client/course/${courseId}/lesson`, this.options);
  }

  create(data: any): Observable<any> {
    return this.httpClient.post(`${this.host}${this.endpoint}`, data, this.options);
  }

  update(id: number, data: any) {
    return this.httpClient.put(`${this.host}${this.endpoint}/${id}`, data, this.options);
  }

  delete(id: number): Observable<any> {
    return this.httpClient.delete(`${this.host}${this.endpoint}/${id}`, this.options);
  }

  transmission(action: string, id: number, layout = null) {
    return this.httpClient.post(`${this.host}${this.endpoint}/transmission/${action}/${id}`, {layout}, this.options);
  }

  ping(filename: string): Observable<any> {
    return this.httpClient.get(`${this.host}/api/v2/client/event/live/gallery/file/${filename}`, this.options);
  }

  order(ids: Array<number>) {
    const stringIds = ids.join(',');
    return this.httpClient.post(`${this.host}/api/v2/client/lesson/order`, {order: stringIds}, this.options);
  }

  get host(): string {
    let host = localStorage.getItem('apiURL');

    if (!host) {
      host = sessionStorage.getItem('apiURL');
    }

    return host;
  }

  get options(): any {
    const token = sessionStorage.getItem('token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: token
    });

    return {
      headers
    };
  }

}
