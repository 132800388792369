import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Lesson } from 'src/app/@core/model/lesson.model';
import { LessonRepository } from '../../@core/repository/lesson.repository';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent {

  icon = {
    video: 'play_circle_filled',
    pdf: 'picture_as_pdf',
    quiz: 'contact_support',
    studio: 'movie',
    text: 'subject'
  };

  @Input() lessonsArray: Array<Lesson>;
  @Input() lessonSelected: Lesson;
  @Output() removeLessonOutput = new EventEmitter();
  @Output() selectLessonOutput = new EventEmitter();

  sortablejsOptions: any;

  constructor(
    public lessonRepository: LessonRepository
  ) {
    this.sortablejsOptions = {
      onUpdate: (event: any) => {
        const order = [];
        this.lessonsArray.map(item => {
          order.push(item.id);
        });
        this.lessonRepository.order(order).then(res => {
          console.log(res);
        });
      }
    };
  }

  selectLesson(lesson: Lesson) {
    this.selectLessonOutput.emit(lesson);
  }

  removeLesson(lesson: Lesson) {
    this.removeLessonOutput.emit(lesson);
  }

}
