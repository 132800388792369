export class Lesson {
  public id: number;
  public type: string;
  public course_id: number;
  public title: string;
  public content: string;
  public quiz: Quiz;
}

export class Quiz {
  title: string;
  subtitle: string;
  questions: Array<QuizQuestion>;

  constructor() {
    this.title = 'Quiz sem título';
    this.subtitle = 'A descrição do seu quiz aqui';
    this.questions = [];
    this.questions.push(new QuizQuestion());
  }
}

export class QuizQuestion {
  title: string;
  options: Array<QuizQuestionOptions>;
  correct: number;

  constructor() {
    this.title = 'Pergunta sem título';
    this.options = [];
    this.options.push(new QuizQuestionOptions());
  }
}

export class QuizQuestionOptions {
  id: number;
  label: string;

  constructor() {
    this.id = Date.now();
    this.label = 'Opção sem label';
  }
}
