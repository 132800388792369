import { Component, Input } from '@angular/core';
import { Course } from 'src/app/@core/model/course.model';
import { MatDialog } from '@angular/material';
import { CoursesDialogComponent } from '../course-dialog/course-dialog.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})

export class NavbarComponent {
  @Input() course: Course;

  constructor(
    public dialog: MatDialog
  ) {}

  updateCourse() {
    const dialogRef = this.dialog.open(CoursesDialogComponent, {
      width: '600px',
      data: {
        course: this.course
      }
    });

    dialogRef.afterClosed().subscribe((result: Course) => {
      if (result) {
        this.course = result;
      }
    });
  }


}
