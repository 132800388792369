import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({name: 'stream'})
export class StreamPipe implements PipeTransform {

  public url: string;

  constructor(private domSanitizer: DomSanitizer) {
    const lu = JSON.parse(sessionStorage.getItem('loggedUser')).data;
    this.url = lu.link;
  }

  transform(video: string, arg?: string, safe = true): SafeResourceUrl | string {
    if (arg === 'link') {
      return this.getVideoUri(video, safe);
    }
    if (arg === 'thumb') {
      return this.getVideoThumb(video, safe);
    }
    if (arg === 'id') {
      return this.getVideoId(video);
    }
    return video;
  }

  getVideoThumb(url: string, safe: boolean): SafeResourceUrl | string {
    if (safe) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(this.url + 'videos/thumb/' + this.getVideoId(url));
    }
    return this.url + 'videos/thumb/' + this.getVideoId(url);
  }

  getVideoUri(url: string, safe: boolean): SafeResourceUrl | string {
    if (safe) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(this.url + 'videos/' + this.getVideoId(url) + '/480');
    }
    return this.url + 'videos/' + this.getVideoId(url) + '/480';
  }

  getVideoId(url: string): string {
    if (this.isUrl(url)) {
      const split = url.split('/');
      return split[4];
    }
    return url;
  }

  isUrl(s: string): boolean {
    const regexp = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    return regexp.test(s);
  }


}
